import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/kaja2.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    return (
        <div>
            <Parallax small filter image={require("assets/img/domenico-loia-hGV2TfOh0ns-unsplash.jpg")}/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                                <div className={classes.profile}>
                                    <div>
                                        <img src={profile} alt="..." className={imageClasses}/>
                                    </div>
                                    <div className={classes.name}>
                                        <h3 className={classes.title}>Kaja Kvinge Akselsen</h3>
                                        <h6>SENIOR SOFTWARE ENGINEER</h6>
                                        <p>Senior java developer with 15 years of experience from different systems in
                                            banking, energy and statistics. I'm a full stack developer focusing on Java
                                            and React. I love working in an agile environment and prefer beeing part of
                                            the whole process from analysis, programming all the way to deployment.
                                        </p>
                                        <p>
                                            Currently looking for new projects, available from august/september
                                            2020. </p>
                                        <p>Have a closer look at my profile at</p>
                                        <Button href="https://www.linkedin.com/in/kajakvingeakselsen/"
                                                className={classes.margin5}>
                                            <i className={"fab fa-linkedin"}/>
                                            LinkedIn
                                        </Button>
                                        <p></p>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
}
